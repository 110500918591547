import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentStep,
  setNextTourStep,
  setPreviousTourStep,
} from "../../redux/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";

export default function AudioPlayer() {
  /* {currentTourStep??} */

  const { language_code } = useParams();
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const tour = useSelector((state) => state.tour.selectedTour);
  const tourSteps = tour.tourSteps;
  const dispatch = useDispatch();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openVolumeBar, setOpenVolumeBar] = useState(false);
  const [volume, setVolume] = useState(50);
  const [currentSeekTime, setCurrentSeekTime] = useState(formatToTime(0));
  const [maxSeekTime, setMaxSeekTime] = useState(0);

  function formatToTime(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = JSON.stringify(Math.floor(duration % 60)).padStart(2, 0);
    return `${minutes}:${seconds}`;
  }

  function handleNextStep() {
    // if user current Step = = 1? <ProtectedRoutes tam yra />
    if (user.currentStep === 1 && !user.isTicketPurchased) {
      navigate(`/${language_code}/ticket`);
    } else if (user.isTicketPurchased && user.currentStep === 1) {
      setIsPlaying(false);
      setCurrentProgress(0);
      setCurrentSeekTime(formatToTime(0));
      dispatch(setNextTourStep());
    } else if (
      user.isTicketPurchased &&
      user.currentStep < tourSteps.length &&
      user.currentStep !== 1
    ) {
      setIsPlaying(false);
      setCurrentProgress(0);
      setCurrentSeekTime(formatToTime(0));
      dispatch(setNextTourStep());
    } else {
      dispatch(changeCurrentStep(1));
      navigate(`/${language_code}/feedback`);
    }
  }

  function handlePreviousStep() {
    if (user.currentStep > 1) {
      setIsPlaying(false);
      setCurrentProgress(0);
      setCurrentSeekTime(formatToTime(0));
      dispatch(setPreviousTourStep());
    }
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white py-4 shadow-up">
      {/* container */}
      <div className="container">
        <audio
          ref={audioRef}
          src={user.currentTourStep?.audioUrl}
          preload="metadata"
          onLoadedData={() => {
            setMaxSeekTime(formatToTime(audioRef.current.duration));
            setCurrentSeekTime(formatToTime(audioRef.current.currentTime));
          }}
          onEnded={() => {
            setCurrentProgress(100);
            setIsPlaying(false);

            handleNextStep();
          }}
          onTimeUpdate={(e) => {
            const calculatedProgress =
              (e.currentTarget.currentTime * 100) / audioRef.current.duration;
            if (!isNaN(calculatedProgress)) {
              setCurrentProgress(calculatedProgress);
            }
            setCurrentSeekTime(formatToTime(audioRef.current.currentTime));
          }}
        />

        <div className="audio-bar mb-0">
          <div className="relative bg-[#D9D9D9] max-w-[400px] h-1 rounded">
            <div
              id="progress-slider"
              className="absolute top-0 left-0 bg-[#D54746] h-1 rounded-l-md w-0"
              style={{ width: currentProgress + "%" }}
            ></div>
            <input
              id="slider"
              min="0"
              value={currentProgress}
              className="absolute top-0 left-0 appearance-none w-full h-1 rounded bg-transparent outline-none
                      "
              type="range"
              onChange={(e) => {
                const inputValue = e.currentTarget.value;
                setCurrentProgress(inputValue);
                const { duration } = audioRef.current;
                audioRef.current.currentTime = (inputValue * duration) / 100;
              }}
            />
          </div>
          <div className="flex items-center justify-between text-[14px] pt-2">
            <span className="current-time">{currentSeekTime}</span>
            <span className="max-duration">{maxSeekTime !== 0  && maxSeekTime}</span>
          </div>
        </div>

        {/* CONTROLS  */}
        <div className="audio-controls flex items-center justify-between px-5 text-[#383838]">
          <div className="flex flex-col items-center justify-center">
            {/*  VOLUME  */}
            <button
              id="volumeButton"
              onClick={() => {
                setOpenVolumeBar((state) => !state);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                fill="currentColor"
                className="bi bi-volume-up"
                viewBox="0 0 16 16"
              >
                <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z" />
                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z" />
                <path d="M10.025 8a4.5 4.5 0 0 1-1.318 3.182L8 10.475A3.5 3.5 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0 1 10.025 8M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11" />
              </svg>
            </button>
          </div>
          <div className="flex max-w-[200px] w-full items-center justify-between">
            <button
              id="prevStep"
              className=" rounded-full border-[3px] border-[#383838] w-[40px] h-[40px] flex items-center justify-center"
              onClick={() => {
                handlePreviousStep();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 18 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            </button>
            {/*  #D54746 */}
            {isPlaying ? (
              <button
                id="pauseButton"
                onClick={() => {
                  if (!audioRef.current.paused) {
                    audioRef.current.pause();
                    setIsPlaying(false);
                  }
                }}
                className=""
              >
                <svg
                  width="78"
                  height="78"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="39" cy="39" r="39" fill="#151515" />
                  <path
                    d="M30.6 23.875C28.6125 23.875 27 25.5127 27 27.5312V49.4688C27 51.4873 28.6125 53.125 30.6 53.125H33C34.9875 53.125 36.6 51.4873 36.6 49.4688V27.5312C36.6 25.5127 34.9875 23.875 33 23.875H30.6ZM45 23.875C43.0125 23.875 41.4 25.5127 41.4 27.5312V49.4688C41.4 51.4873 43.0125 53.125 45 53.125H47.4C49.3875 53.125 51 51.4873 51 49.4688V27.5312C51 25.5127 49.3875 23.875 47.4 23.875H45Z"
                    fill="white"
                  />
                </svg>
              </button>
            ) : (
              <button
                id="playButton"
                onClick={() => {
                  if (audioRef.current.paused) {
                    audioRef.current.play();
                    setIsPlaying(true);
                  } else {
                    audioRef.current.pause();
                    setIsPlaying(false);
                  }
                }}
                className=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="78"
                  height="78"
                  fill="none"
                  className="bi bi-play-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#151515"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"
                  />
                </svg>
              </button>
            )}

            <button
              id="nextStep"
              className="rounded-full border-[3px] border-[#383838] w-[40px] h-[40px] flex items-center justify-center"
              onClick={() => {
                handleNextStep();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-chevron-right"
                viewBox="0 0 14 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </button>
          </div>
          <button
            id="forward"
            className=""
            onClick={() => {
              const { currentTime, duration } = audioRef.current;
              if (currentTime + 30 > duration) {
                audioRef.current.currentTime = duration;
              } else {
                audioRef.current.currentTime += 30;
              }
            }}
          >
            <svg
              width="36"
              height="37"
              viewBox="0 0 36 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.0557 21.8135C12.6219 21.8136 12.1925 21.7266 11.793 21.5576C11.3708 21.3794 10.983 21.1285 10.6475 20.8164V22.6367C10.9822 22.8927 11.3603 23.0862 11.7637 23.208C12.2352 23.3455 12.7247 23.4114 13.2158 23.4033C13.6283 23.4053 14.038 23.3359 14.4268 23.1982C14.8004 23.0698 15.143 22.8645 15.4326 22.5957C16.0596 22.0378 16.3727 21.3089 16.3721 20.4092C16.3721 19.7738 16.2035 19.2357 15.8662 18.7949C15.5094 18.3421 15.0186 18.0136 14.4639 17.8564L16.3271 14.2891V14.2461H10.7217V15.8897H13.7891L12.2324 18.8496V18.8945C12.9583 18.8743 13.5407 18.9964 13.9795 19.2607C14.1862 19.3741 14.3575 19.5425 14.4742 19.7473C14.5909 19.9521 14.6485 20.1853 14.6406 20.4209C14.6406 20.8812 14.5036 21.2285 14.2295 21.4629C13.9554 21.6973 13.5641 21.8141 13.0557 21.8135ZM23.7461 14.4258C23.3006 14.208 22.8113 14.0947 22.3154 14.0947C21.8196 14.0947 21.3302 14.208 20.8848 14.4258C20.4373 14.6549 20.0482 14.9832 19.7471 15.3857C19.4163 15.8317 19.17 16.3344 19.0205 16.8691C18.678 18.098 18.678 19.3971 19.0205 20.626C19.1701 21.1601 19.4163 21.6622 19.7471 22.1074C20.0481 22.5105 20.4372 22.8395 20.8848 23.0693C21.3304 23.2865 21.8197 23.3994 22.3154 23.3994C22.8112 23.3994 23.3004 23.2865 23.7461 23.0693C24.1936 22.8395 24.5827 22.5105 24.8838 22.1074C25.2138 21.6618 25.46 21.1598 25.6104 20.626C25.9528 19.3971 25.9528 18.098 25.6104 16.8691C25.46 16.3348 25.2138 15.8321 24.8838 15.3857C24.5827 14.9832 24.1935 14.6549 23.7461 14.4258ZM23.5996 20.9297C23.3112 21.4629 22.8831 21.7295 22.3154 21.7295C21.7383 21.7295 21.3018 21.4414 21.0098 20.8633C20.7344 20.3835 20.5973 19.679 20.5986 18.75C20.5999 17.821 20.7464 17.0921 21.0381 16.5635C21.3317 16.0355 21.7575 15.7715 22.3154 15.7715C22.8633 15.7715 23.2861 16.0391 23.585 16.5781C23.8838 17.1172 24.0322 17.8398 24.0322 18.75C24.0322 19.6602 23.8877 20.3936 23.5996 20.9297Z"
                fill="#383838"
              />
              <path
                d="M35.1728 15.8516L32.7217 16.3398C33.1558 18.514 33.103 20.7574 32.5668 22.9087C32.0307 25.0601 31.0246 27.0659 29.6209 28.7821C28.2172 30.4982 26.4508 31.8821 24.4485 32.8343C22.4463 33.7865 20.2579 34.2832 18.0408 34.2889C15.8237 34.2945 13.6329 33.809 11.6258 32.867C9.6187 31.9251 7.8452 30.5503 6.43277 28.8413C5.02033 27.1323 4.00403 25.1316 3.45692 22.9831C2.90981 20.8345 2.84548 18.5914 3.26854 16.415C4.79979 8.52637 12.2812 3.2832 20.1631 4.44629L19.6113 5.98828L26.1289 5.20703L21.5928 0.462891L21.0273 2.04883C11.6523 0.410155 2.63475 6.57422 0.812486 15.9395C-0.0734198 20.4974 0.88748 25.2207 3.48383 29.0702C6.08017 32.9198 10.0993 35.5804 14.6572 36.4668C15.7708 36.6833 16.9026 36.7929 18.0371 36.7939C20.6241 36.7871 23.1775 36.2075 25.5139 35.0966C27.8503 33.9857 29.9117 32.3711 31.55 30.3689C33.1882 28.3667 34.3628 26.0265 34.9892 23.5165C35.6156 21.0064 35.6783 18.3887 35.1728 15.8516Z"
                fill="#383838"
              />
            </svg>
          </button>
        </div>

        {openVolumeBar && (
          <div className="volumeWrapper mt-2 relative bg-[#D9D9D9] w-1/3 h-1 rounded">
            <div
              id="volume-slider"
              className="absolute top-0 left-0 bg-[#D54746] h-1 rounded-l-md w-0"
              style={{ width: volume + "%" }}
            ></div>
            <input
              className="absolute cursor-pointer top-0 left-0 appearance-none w-full h-1 rounded bg-transparent outline-none "
              type="range"
              min="0"
              max="100"
              value={volume}
              onChange={(e) => {
                setVolume(e.currentTarget.value);
                audioRef.current.volume = e.currentTarget.value / 100;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
