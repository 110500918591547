import React, { useEffect } from "react";
import Navbar from "../components/Player/Navbar";
import { useSelector } from "react-redux";

export default function AppWrapper({ children }) {
  const location = window.location.pathname.split("/");
  const tour = useSelector((state) => state.tour);
  const {isLoading} = tour;

  return (
    <div className="container mb-5">
      {(!(location.includes('introduction') || location.includes('feedback') || isLoading)) && <Navbar />}
      {children}
    </div>
  );
}
