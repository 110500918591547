function translate(language_code, code) {
  const translations = [
    {
      language_code: "lt",
      translationValues: [
        {
          code: "Kraunasi",
          text: "Kraunasi",
        },
        {
          code: "Jūsų kelionė prasideda",
          text: "Jūsų kelionė prasideda",
        },
        {
          code: "Supratau",
          text: "Supratau",
        },
        {
          code: "Pasirinkite dramaturą",
          text: "Pasirinkite dramaturą"
        }
        ,
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "Norint tęsti, įsigykite bilietą kasose",
        },
        {
          code: "Įveskite kodą",
          text: "Įveskite kodą",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
        },
        {
          code: "Tęsti",
          text: "Tęsti",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Klausytis nemokamos versijos",
        },
        {
          code: "Pradžia",
          text: "Pradžia",
        },
        {
          code: "Apžvalga",
          text: "Apžvalga",
        },
        {
          code: "Grįžti",
          text: "Grįžti",
        },
        {
          code: "intro_text",
          text: "<p>Dramaturas - dramos ir audio turo derinys</p>\n<p>&Scaron;iuose pasakojimuose patirsite Raudondvario dvare vykusias istorijas, sukurtas remiantis faktiniais įvykiais. Turinys buvo kuriamas naudojant dirbtinio intelekto įrankius istorijos ra&scaron;ymui ir įgarsinimui anglų bei vokiečių kalbomis naudojant dirbtinį balsą.</p>\n<p>Malonaus laiko!</p>\n<p>P.S. Pirma stotelė nemokama. Norint tęsti, įsigykite bilietą kasose.</p>",
        },
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "Norint tęsti, įsigykite bilietą kasose",
        },
        {
          code: "Įveskite kodą",
          text: "Įveskite kodą",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
        },
        {
          code: "Tęsti",
          text: "Tęsti",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Klausytis nemokamos versijos",
        },
        {
          code: "Pradėti",
          text: "Pradėti",
        },
        {
          code: "Turo apžvalga",
          text: "Turo apžvalga",
        },
        {
          code: "stotelė",
          text: "stotelė",
        },
        {
          code: "question1",
          text: "<p>Kaip vertinate savo bendrą patirtį naudojantis dirbtinio intelekto sukurtu audiogidu Raudondvario dvare?</p>",
        },
        {
          code: "question2",
          text: "<p>Ar audiogido pasakojimo stilius jums pasirodė įdomus ir patrauklus?</p>",
        },
        {
          code: "question3",
          text: "<p>Ar manote, kad audiogido pateikiama informacija buvo pakankamai i&scaron;sami ir įdomi?</p>",
        },
        {
          code: "question4",
          text: "<p>Ar audiogido pasakojimai padėjo jums geriau įsivaizduoti istorinius dvaro įvykius ir gyvenimą?</p>",
        },
        {
          code: "question5",
          text: "<p>Kaip audiogidas prisidėjo prie jūsų bendro įspūdžio apie Raudondvario dvarą?</p>",
        },
        {
          code: "question6",
          text: "<p>Ar rekomenduotumėte &scaron;į audiogidą kitiems Raudondvario dvaro lankytojams?</p>",
        },
        {
          code: "Ačiū už Jūsų atsakymus! Lauksime sugrįžtant!",
          text: "Ačiū už Jūsų atsakymus! Lauksime sugrįžtant!",
        },
        {
          code: "Šio kodo neradome. Bandykite dar kartą.",
          text: "Šio kodo neradome. Bandykite dar kartą.",
        },
      ],
    },
    {
      language_code: "en",
      translationValues: [
        {
          code: "Kraunasi",
          text: "Loading",
        },
        {
          code: "Jūsų kelionė prasideda",
          text: "Your journey begins",
        },
        {
          code: "Supratau",
          text: "Got it",
        },
        {
          code: "Pasirinkite dramaturą",
          text: "Select the drama-tour"
        }
        ,
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "To continue, purchase a ticket at the box office",
        },
        {
          code: "Įveskite kodą",
          text: "Enter the code",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "The collected funds will be used for the development and improvement of other parts of the project.",
        },
        {
          code: "Tęsti",
          text: "Continue",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Listen to the free version",
        },
        {
          code: "Pradžia",
          text: "Home",
        },
        {
          code: "Apžvalga",
          text: "Overview",
        },
        {
          code: "Grįžti",
          text: "Back",
        },
        {
          code: "intro_text",
          text: "<p>Dramaturas - a combination of drama and audio tour</p><p> In these stories, you will experience the stories that took place in Raudondvaris Manor, created based on actual events. The content was created using artificial intelligence tools to write and voice the story in English and German using an artificial voice.</p><p> Have a nice time!</p><p> PS The first stop is free. To continue, purchase a ticket at the box office.</p>",
        },
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "To continue, purchase a ticket at the box office",
        },
        {
          code: "Įveskite kodą",
          text: "Enter the code",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "The collected funds will be used for the development and improvement of other parts of the project.",
        },
        {
          code: "Tęsti",
          text: "Continue",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Listen to the free version",
        },
        {
          code: "Pradėti",
          text: "Start",
        },
        {
          code: "Turo apžvalga",
          text: "Tour overview",
        },
        {
          code: "stotelė",
          text: "stop",
        },
        {
          code: "question1",
          text: "<p>How do you rate your overall experience using the artificial intelligence audio guide at Raudondvaris Manor?</p>",
        },
        {
          code: "question2",
          text: "<p>Did you find the audio guide&#39;s narrative style interesting and engaging?</p>",
        },
        {
          code: "question3",
          text: "<p>Do you think that the information provided by the audio guide was sufficiently detailed and interesting?</p>",
        },
        {
          code: "question4",
          text: "<p>Did the stories of the audio guide help you to better imagine the historical events and life of the manor?</p>",
        },
        {
          code: "question5",
          text: "<p>How did the audio guide contribute to your overall impression of Raudondvaris Manor?</p>",
        },
        {
          code: "question6",
          text: "<p>Would you recommend this audio guide to other visitors of Raudondvaris Manor?</p>",
        },
        {
          code: "Ačiū už Jūsų atsakymus! Lauksime sugrįžtant!",
          text: "Thank you for your answers! We look forward to your return!",
        },
        {
          code: "Šio kodo neradome. Bandykite dar kartą.",
          text: "We did not find this code. Try again.",
        },
      ],
    },
    {
      language_code: "de",
      translationValues: [
        {
          code: "Kraunasi",
          text: "Wird geladen",
        },
        {
          code: "Jūsų kelionė prasideda",
          text: "Ihre Reise beginnt",
        },
        {
          code: "Supratau",
          text: "Habe es",
        },
        {
          code: "Pasirinkite dramaturą",
          text: "Drama-tour auswählen"
        },
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "Um fortzufahren, kaufen Sie ein Ticket an der Abendkasse",
        },
        {
          code: "Įveskite kodą",
          text: "Code eingeben",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "Die gesammelten Mittel werden für die Entwicklung und Verbesserung anderer Teile des Projekts verwendet.",
        },
        {
          code: "Tęsti",
          text: "Weitermachen",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Hören Sie sich die kostenlose Version an",
        },
        {
          code: "Pradžia",
          text: "Start",
        },
        {
          code: "Apžvalga",
          text: "Überblick",
        },
        {
          code: "Grįžti",
          text: "Zurück",
        },
        {
          code: "intro_text",
          text: "<p>Dramaturien – eine Kombination aus Schauspiel und Audioführung</p><p> In diesen Geschichten erleben Sie die Geschichten, die sich im Raudondvaris Manor abspielten und auf tatsächlichen Ereignissen basieren. Der Inhalt wurde mithilfe von Tools der künstlichen Intelligenz erstellt, um die Geschichte mit einer künstlichen Stimme auf Englisch und Deutsch zu schreiben und vorzutragen.</p><p> Viel Spaß!</p><p> PS: Der erste Stopp ist kostenlos. Um fortzufahren, kaufen Sie ein Ticket an der Abendkasse.</p>",
        },
        {
          code: "Norint tęsti, įsigykite bilietą kasose",
          text: "Um fortzufahren, kaufen Sie ein Ticket an der Abendkasse",
        },
        {
          code: "Įveskite kodą",
          text: "Code eingeben",
        },
        {
          code: "Surinktos lėšos bus panaudotos kitų projekto dalių kūrimui ir tobulinimui.",
          text: "Die gesammelten Mittel werden für die Entwicklung und Verbesserung anderer Teile des Projekts verwendet.",
        },
        {
          code: "Tęsti",
          text: "Weitermachen",
        },
        {
          code: "Klausytis nemokamos versijos",
          text: "Hören Sie sich die kostenlose Version an",
        },
        {
          code: "Pradėti",
          text: "Start",
        },
        {
          code: "Turo apžvalga",
          text: "Tourübersicht",
        },
        {
          code: "stotelė",
          text: "stoppen",
        },
        {
          code: "question1",
          text: "<p>Wie bewerten Sie insgesamt Ihr Erlebnis mit dem Audioguide mit künstlicher Intelligenz im Raudondvaris Manor?</p>",
        },
        {
          code: "question2",
          text: "<p>Fanden Sie den Erzählstil des Audioguides interessant und ansprechend?</p>",
        },
        {
          code: "question3",
          text: "<p>Finden Sie, dass die Informationen des Audioguides ausreichend detailliert und interessant waren?</p>",
        },
        {
          code: "question4",
          text: "<p>Haben Ihnen die Geschichten des Audioguides dabei geholfen, sich die historischen Ereignisse und das Leben auf dem Gut besser vorzustellen?</p>",
        },
        {
          code: "question5",
          text: "<p>Wie hat der Audioguide zu Ihrem Gesamteindruck von Raudondvaris Manor beigetragen?</p>",
        },
        {
          code: "question6",
          text: "<p>Würden Sie diesen Audioguide anderen Besuchern des Raudondvaris Manor empfehlen?</p>",
        },
        {
          code: "Ačiū už Jūsų atsakymus! Lauksime sugrįžtant!",
          text: "Danke für deine Antworten! Wir freuen uns auf Ihre Rückkehr!",
        },
        {
          code: "Šio kodo neradome. Bandykite dar kartą.",
          text: "Wir haben diesen Code nicht gefunden. Versuchen Sie es erneut.",
        },
      ],
    },
  ];

  if (!language_code && code) {
    translations[0].translationValues.find(
      (translation) => translation.code === code
    );
  }
  if (!code) {
    return "";
  }

  return translations
    .find((language) => language.language_code === language_code)
    .translationValues.find((translation) => translation.code === code).text;
}

export default translate;
