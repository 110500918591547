import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

/* const initialState = {
  tours: {
    progress: 0,
    isLoading: true,
    lang_code: "",
    id: "",
    language_id: "",
    name: "",
    slug: "",
    imageUrl: "",
    position_nb: "",
    tourSteps: [],
  },
}; */

const initialState = {
  progress: 0,
  isLoading: false,
  lang_code: "",
  tours: [
    {
      id: "",
      language_id: "",
      name: "",
      slug: "",
      imageUrl: "",
      position_nb: "",
      /* tourSteps: [] */
    },
    {
      id: "",
      language_id: "",
      name: "",
      slug: "",
      imageUrl: "",
      position_nb: "",
      /*  tourSteps: [] */
    },
  ],
  overviewSteps: [],
  selectedTour: {
    id: "",
    language_id: "",
    name: "",
    slug: "",
    imageUrl: "",
    position_nb: "",
    tourSteps: [],
  },
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
    setTour: (state, action) => {
      state.selectedTour = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
    setTourId: (state, action) => {
      state.selectedTour.id = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTour.pending, (state, action) => {
      state.progress = 0;
      state.isLoading = true;
    });
    builder.addCase(fetchTour.fulfilled, (state, action) => {
      const tour = action.payload;
      state.selectedTour = tour;
      state.isLoading = false;
      state.progress = 100;
    });
  },
});

export const fetchTour = createAsyncThunk(
  "tour/fetchTour",
  async (args, { dispatch }) => {
    dispatch(updateProgress(10));
    const url =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;

    const { language_code, tour_id } = args;

    const route = `${url}/api/${language_code}/${tour_id}/tourwsteps`;

    const response = await fetch(route);

    const data = await response.json();

    /* dispatch(updateProgress(30)); */
    dispatch(updateProgress(100));

    const { tour } = data;

/*     const audioUrls = tour.tourSteps.map((tourStep) => tourStep.audioUrl);
    const imageUrls = tour.tourSteps.map((tourStep) => tourStep.imageUrl);

    const allMedia = audioUrls.concat(imageUrls); */
    // cache tour media
   /*  if ("serviceWorker" in navigator && "caches" in window) {
      const CACHE_NAME = "v1"; // process.env.REACT_APP_CACHE_NAME
      const cache = await caches.open(CACHE_NAME);
      for (let i = 0; i < allMedia.length; i++) {
        const alreadyCached = await cache.match(allMedia[i]);

        if (!alreadyCached) {
          await cache.add(allMedia[i]);
        }

        const progress = ((i + 1) / allMedia.length) * 50 + 50;
        dispatch(tourSlice.actions.updateProgress(progress));
      }
    } */

    return tour;
  }
);

export const {setTourId, setTours, setTour, setLoading, updateProgress } =
  tourSlice.actions;
export default tourSlice.reducer;
