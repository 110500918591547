import React, { useEffect, useState } from "react";
import AppWrapper from "../layouts/AppWrapper";
import translate from "../utils/translations";
import { useParams, useNavigate } from "react-router-dom";
import { changeCurrentStep, setCurrentTourStep } from "../redux/user/userSlice";
import {
  fetchTour,
  setLoading,
  setTour,
  setTourId,
} from "../redux/tour/tourSlice";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";

export default function SelectTour() {
  const { language_code } = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [tours, setTours] = useState([]);
  const [tourID, setTourID] = useState("");

  /*  */
  const tour = useSelector((state) => state.tour.selectedTour);
  const tourSlice = useSelector((state) => state.tour);
  const { lang_code, progress, isLoading } = tourSlice;
  /*  */

  async function fetchTours(language_code) {
    const url =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL;
    const response = await fetch(`${url}/api/${language_code}/tours`);
    const data = await response.json();
    const { tours } = data;

    setTours(tours);
  }

  useEffect(() => {
    fetchTours(language_code);
  }, []);

  useEffect(() => {
    if(!isLoading && progress == 100 && tourID === tour.id){
      dispatch(setLoading(false));
      navigate(`/${language_code}/player`);
    }
  }, [isLoading])

  return (
    <>
      {isLoading ? (
        <LoadingScreen progress={progress} />
      ) : (
        <AppWrapper>
          <h1 className="text-black text-[24px] font-bold my-4 text-center">
            {translate(language_code, "Pasirinkite dramaturą")}
          </h1>
          {tours.length > 0 && (
            <ul className="flex flex-col items-center justify-center gap-8">
              {tours.map((tour, i) => (
                <li
                  key={i}
                  className="overflow-hidden rounded-2xl border border-[#ccc] flex flex-col items-center justify-center w-full"
                >
                  <img
                    className="w-[400px] h-[231px] object-cover mb-4"
                    src={tour.imageUrl}
                    alt=""
                  />

                  <p className="text-[23px] font-normal text-center leading-7 mb-4">
                    {tour.name}
                  </p>
                  <div className="flex items-center justify-between gap-6 w-11/12 mb-6">
                    <button
                      onClick={() => {
                        dispatch(setTourId(tour.id));
                        const args = {
                          language_code,
                          tour_id: tour.id,
                        }
                        setTourID(tour.id);
                        dispatch(changeCurrentStep(1));
                        dispatch(setLoading(true));
                        dispatch(fetchTour(args))
                      }}
                      className="inline-block text-[16px] font-medium w-full text-center px-5 py-3 bg-[#D54746] rounded-3xl text-white"
                    >
                      {translate(language_code, "Pradėti")}
                    </button>
                    <button
                      className="inline-block text-[16px] font-medium w-full text-center px-5 py-3 bg-black rounded-3xl text-white"
                      onClick={() => {
                        dispatch(setTour(tour));/* set selectedTour.tourSteps = tour.tourSteps */
                        /* dispatch(setOverview(tour.tourSteps)); */
                        navigate(`/${language_code}/overview`);
                      }}
                    >
                      {translate(language_code, "Turo apžvalga")}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </AppWrapper>
      )}
    </>
  );
}
