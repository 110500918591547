import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import translate from "../../utils/translations";
import { useDispatch } from "react-redux";

export default function Navbar() {
  const { language_code } = useParams();
  const dispatch = useDispatch();

  const isOverview = window.location.pathname.split("/").includes("overview");
  const isTicketPage = window.location.pathname.split("/").includes("ticket");
  const isSelectionPage = window.location.pathname
    .split("/")
    .includes("tour-select");

  return (
    <nav className="grid grid-cols-3 gap-3 pb-2">
      <Link to={-1} className="flex items-center justify-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
          />
        </svg>
        <p className="text-[15px] ml-2">{translate(language_code, "Grįžti")}</p>
      </Link>
      <Link to="/" className="flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-house"
          viewBox="0 0 16 16"
        >
          <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
        </svg>
        <p className="text-[15px] ml-2">
          {translate(language_code, "Pradžia")}
        </p>
      </Link>
      {!isTicketPage && !isSelectionPage && (
        <Link
          to={`/${language_code}/overview`}
          className={`flex items-center justify-center ${
            isOverview ? "text-[#AF0002]" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill={isOverview ? "#AF0002" : ""}
            className="bi bi-list-task"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
            />
            <path
              fillRule="evenodd"
              d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"
            />
            <path
              fillRule="evenodd"
              d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
            />
          </svg>
          <p className="text-[15px] ml-2">
            {translate(language_code, "Apžvalga")}
          </p>
        </Link>
      )}
    </nav>
  );
}
