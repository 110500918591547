import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentStep, setCurrentTourStep } from "../redux/user/userSlice";
import { fetchTour, setLoading } from "../redux/tour/tourSlice";
import IntroductionScreen from "../components/IntroductionScreen";
import LoadingScreen from "../components/LoadingScreen";
import SelectTour from "./SelectTour";

export default function Introduction() {
  return <IntroductionScreen />;
}
